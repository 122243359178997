import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="comment-textbox"
export default class extends Controller {
  static targets = ["checkbox", "toggle", "textbox"];

  connect() {}

  togglePinnedAndFocus() {
    this.togglePinned();
    this.focusTextbox();
  }

  togglePinned() {
    if (this.checkboxTarget.checked) {
      this.checkboxTarget.checked = false;
      this.toggleTarget.classList.remove(
        "bg-plum",
        "text-white",
        "hover:bg-plum/80",
      );
      this.toggleTarget.classList.add(
        "bg-gray-100",
        "text-plum/60",
        "hover:bg-gray-200",
      );
    } else {
      this.checkboxTarget.checked = true;
      this.toggleTarget.classList.remove(
        "bg-gray-100",
        "text-plum/60",
        "hover:bg-gray-200",
      );
      this.toggleTarget.classList.add(
        "bg-plum",
        "text-white",
        "hover:bg-plum/60",
      );
    }
  }

  focusTextbox() {
    this.textboxTarget.focus();
  }
}
