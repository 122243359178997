import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["record", "recordDetails", "recordDetailsToggle"];
  static values = {
    selectedRecordId: String,
    detailsUrl: String,
    messagesUrl: String,
    isMobile: Boolean,
  };

  connect() {
    this.updateIsMobile();
    window.addEventListener("resize", this.updateIsMobile.bind(this));
  }

  disconnect() {
    window.removeEventListener("resize", this.updateIsMobile.bind(this));
  }

  updateIsMobile() {
    const isMobile = window.innerWidth < 768;
    this.isMobileValue = isMobile;

    if (isMobile) {
      const activeRecord = this.recordTargets.find(
        (record) => record.id === this.selectedRecordIdValue,
      );
      if (activeRecord) {
        const toggle = this.recordDetailsToggleTargets.find(
          (toggle) => toggle.dataset.recordId === this.selectedRecordIdValue,
        );
        this.hideDetails(toggle);
        this.deactivateRecord(this.selectedRecordIdValue);
        this.selectedRecordIdValue = "";
      }
    }
  }

  // DETAILS & MESSAGES TURBO_FRAME DISPLAY LOGIC

  toggleDetails(event) {
    if (this.isMobileValue) return;

    // No record currently showing details
    if (this.selectedRecordIdValue === "") {
      this.selectedRecordIdValue = event.currentTarget.dataset.recordId;
      this.activateRecord(event.currentTarget.dataset.recordId);
      return this.showDetails(event.currentTarget);
    }

    // Record already showing details
    if (this.selectedRecordIdValue === event.currentTarget.dataset.recordId) {
      this.selectedRecordIdValue = "";
      this.deactivateRecord(event.currentTarget.dataset.recordId);
      return this.hideDetails(event.currentTarget);
    }

    // Record showing details, but a different record is clicked
    const previousRecordToggle = this.recordDetailsToggleTargets.find(
      (toggle) => toggle.dataset.recordId === this.selectedRecordIdValue,
    );

    this.hideDetails(previousRecordToggle);
    this.deactivateRecord(this.selectedRecordIdValue);

    this.selectedRecordIdValue = event.currentTarget.dataset.recordId;
    this.activateRecord(event.currentTarget.dataset.recordId);
    return this.showDetails(event.currentTarget);
  }

  activateRecord(recordId) {
    const record = this.recordTargets.find((record) => record.id === recordId);

    const header = record.querySelector("[data-record-header]");
    const toggle = this.recordDetailsToggleTargets.find(
      (toggle) => toggle.dataset.recordId === recordId,
    );

    header.classList.add(
      "sticky",
      "top-0",
      "z-50",
      "bg-white",
      "border-b",
      "shadow-sm",
    );
    toggle.classList.add("bg-gray-100", "hover:bg-gray-50");

    //scroll the selection to the top using the hash id
    setTimeout(() => {
      record.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }, 400);
    // window.location.hash = recordId;
  }

  deactivateRecord(recordId) {
    const record = this.recordTargets.find((record) => record.id === recordId);

    const header = record.querySelector("[data-record-header]");
    const toggle = this.recordDetailsToggleTargets.find(
      (toggle) => toggle.dataset.recordId === recordId,
    );

    header.classList.remove(
      "sticky",
      "top-0",
      "z-50",
      "bg-white",
      "border-b",
      "shadow-sm",
    );
    toggle.classList.remove("bg-gray-100", "hover:bg-gray-50");
  }

  showDetails(toggle) {
    const details = this.recordDetailsTargets.find(
      (detail) => detail.id === `details_${toggle.dataset.recordId}`,
    );
    if (details) {
      this.addTurboFrameSrc(details, toggle.dataset.recordId);
      enter(details);
    }
  }

  hideDetails(toggle) {
    const details = this.recordDetailsTargets.find(
      (detail) => detail.id === `details_${toggle.dataset.recordId}`,
    );
    if (details) {
      this.removeTurboFrameSrc(details, toggle.dataset.recordId);
      leave(details);
    }
  }

  addTurboFrameSrc(target, id) {
    const detailsTurboFrame = target.querySelector(
      `#project_record_details_${id}`,
    );
    const messagesTurboFrame = target.querySelector(`#project_feed_frame`);
    detailsTurboFrame.src = target.dataset.detailsUrl;
    messagesTurboFrame.src = target.dataset.messagesUrl;
  }

  removeTurboFrameSrc(target, id) {
    const detailsTurboFrame = target.querySelector(
      `#project_record_details_${id}`,
    );
    const messagesTurboFrame = target.querySelector(`#project_feed_frame`);
    detailsTurboFrame.src = "";
    messagesTurboFrame.src = "";
  }
}
