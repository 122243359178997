import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="estimate"
export default class extends Controller {
  static targets = ["media"];

  connect() {}

  toggleMedia() {
    this.mediaTargets.forEach((media) => {
      media.classList.toggle("hidden");
    });
  }
}
