import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

// Connects to data-controller="split-button"
export default class extends Controller {
  static targets = ["menu"];

  connect() {}

  toggleMenu() {
    if (this.menuTarget.classList.contains("hidden")) {
      enter(this.menuTarget);
    } else {
      leave(this.menuTarget);
    }
  }

  closeMenu(event) {
    if (
      !this.element.contains(event.target) &&
      !this.menuTarget.classList.contains("hidden")
    ) {
      leave(this.menuTarget);
    }
  }
}
