import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="user-setting"
export default class extends Controller {
  connect() {}

  toggleOn(e) {
    e.preventDefault();

    this.element.querySelectorAll('input[type="checkbox"]').forEach((el) => {
      el.classList.remove("hidden");
      el.checked = true;
    });
  }

  toggleOff(e) {
    e.preventDefault();

    this.element.querySelectorAll('input[type="checkbox"]').forEach((el) => {
      el.classList.remove("hidden");
      el.checked = false;
    });
  }
}
