import type { CalendarCraftworker } from "@/types/index";
import type {
  ConstructReactQueryConfig} from "@/utils/constructReactQuery";
import {
  constructReactQuery
} from "@/utils/constructReactQuery";

type CraftworkersQueryParams = {
  start?: string;
  end?: string;
  options?: Partial<{
    query?: string;
  }>;
};

export const useCalendarCraftworkersQuery = (
  { start, end, options = {} }: CraftworkersQueryParams = {},
  config?: ConstructReactQueryConfig<CalendarCraftworker[]>,
) => {
  const params = new URLSearchParams(
    JSON.parse(
      JSON.stringify({
        ...options,
        start,
        end,
      }),
    ),
  );
  const url = `/api/calendars/craftworkers?${params.toString()}`;
  return constructReactQuery<CalendarCraftworker[]>(url, {
    refetchInterval: 1000 * 60 * 15, // 15 minutes
    ...config,
  });
};
