import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import { Dropzone } from "dropzone";

export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

// Connects to data-controller="dropzone"
export default class extends Controller {
  static targets = ["input"];
  static values = {
    recordType: String,
    recordId: Number,
    fieldName: String,
    attach: Boolean,
  };

  connect() {
    this.dropZone = new Dropzone(this.element, {
      url: this.url,
      headers: this.headers,
      maxFiles: this.maxFiles,
      maxFilesize: this.maxFileSize,
      acceptedFiles: this.acceptedFiles,
      addRemoveLinks: this.addRemoveLinks,
      autoQueue: false,
    });

    this.hideFileInput();
    this.bindEvents();
    this.files = [];
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    this.dropZone.on("addedfile", (file) => {
      setTimeout(() => {
        if (file.accepted) {
          this.files.push(file);
          this.attachFiles();
        }
      }, 200);
    });

    this.dropZone.on("removedfile", (file) => {
      file.controller && removeElement(file.controller.hiddenInput);
    });

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort();
    });
  }

  async attachFiles() {
    if (this.files.length === 0 || this.attaching) {
      return;
    }

    this.attaching = true;

    // If this is an ajax version (attach), then we need to: send the
    // record_type, record_id, name, and signed_id to the
    // /api/v1/file_attachments endpoint
    const file = this.files.shift();
    const controller = new DirectUploadController(this, file);
    controller.start(async ({ signed_id }) => {
      if (this.attachValue == true) {
        await fetch("/api/v1/file_attachments", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": getMetaValue("csrf-token"),
          },
          body: JSON.stringify({
            file_attachment: {
              record_type: this.recordTypeValue,
              record_id: this.recordIdValue,
              name: this.fieldNameValue,
              signed_id,
            },
          }),
        }).then(() => {
          this.attaching = false;
          this.attachFiles();
        });
      }
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = new DirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start(callback) {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;

        // If callback is a function, then pass signed ID back to it:
        if (typeof callback === "function") {
          callback({
            signed_id: attributes.signed_id,
          });
        }

        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", (event) =>
      this.uploadRequestDidProgress(event),
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(this.file.previewTemplate, ".dz-upload").style.width =
      `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}
