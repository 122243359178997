import React from "react";

import ErrorBoundary from "@/components/ErrorBoundary";
import { QueryProvider } from "@/providers/QueryProvider";

type RootProviderProps = {
  children: React.ReactNode;
  fallback?: (error: Error | null) => React.ReactNode;
};

export const RootProvider: React.FC<RootProviderProps> = ({
  children,
  fallback,
}) => {
  return (
    <QueryProvider enableDevtools>
      <ErrorBoundary fallback={fallback}>{children}</ErrorBoundary>
    </QueryProvider>
  );
};
