import React from "react";

import { clsxm } from "@/utils/clsxm";

type Props = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const Sidebar = ({ children, className, ...rest }: Props) => (
  <div
    className={clsxm(
      "h-full w-[300px] overflow-hidden overflow-y-auto bg-white",
      className,
    )}
    {...rest}
  >
    {children}
  </div>
);

export const Main = ({ children, className, ...rest }: Props) => (
  <div
    className={clsxm("h-full flex-1 overflow-y-auto bg-gray-50", className)}
    {...rest}
  >
    {children}
  </div>
);

type SidebarLayoutProps = {
  sidebar: React.ReactNode;
  main: React.ReactNode;
};

export const SidebarLayout = ({ sidebar, main }: SidebarLayoutProps) => (
  <div className="grid h-full grid-cols-[auto_1fr] divide-x overflow-hidden">
    {sidebar}
    {main}
  </div>
);
