import React from "react";

type EstimateListGroupLabelProps = {
  label: string;
};

export const EstimatesListGroupLabel: React.FC<EstimateListGroupLabelProps> = ({
  label,
}) => {
  const humanizedLabel = label
    .replace("_", " ")
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase());
  return (
    <li className="sticky top-0 bg-gray-50 px-4 py-2 font-bold text-gray-500">
      {humanizedLabel}
    </li>
  );
};
