import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

// Connects to data-controller="turbo-modal"
export default class extends Controller {
  static targets = ["modal", "form"];
  formChanged = false; // Add a property to track form changes

  connect() {
    this.element.addEventListener("input", (event) => {
      if (event.target.matches("input, textarea, select")) {
        this.formChanged = true; // Update the property when form fields change
      }
    });
    enter(this.modalTarget);
  }

  closeBackground(e) {
    if ((e && this.modalTarget.contains(e.target)) || this.formChanged) {
      // If clicking inside the modal or the form has been changed, do not close
      return;
    }
    this.hideModal();
  }

  closeWithEscape(e) {
    if (e.key === "Escape") {
      this.hideModal();
    }
  }

  // Reset formChanged on successful submission or when manually hiding the modal
  hideModal(e) {
    if (e) {
      e.preventDefault();
    }

    leave(this.modalTarget).then(() => {
      this.element.parentElement.removeAttribute("src");
      this.element.remove();
      this.formChanged = false; // Reset form change tracking
    });
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.formChanged = false; // Ensure formChanged is reset on successful submission
      this.hideModal();
    }
  }
}
