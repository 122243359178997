import React from "react";

type StackedDateProps = {
  weekday?: string;
  day?: string;
};

export const StackedDate: React.FC<StackedDateProps> = ({ weekday, day }) => {
  return (
    <div className="px-2">
      <p className="text-xs uppercase text-inherit opacity-60">{weekday}</p>
      <p className="text-xl text-inherit">{day}</p>
    </div>
  );
};
