import { useCallback, useEffect } from "react";
import type {WeekOptions} from "date-fns";
import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subYears
} from "date-fns";

import { useLocalStorage } from "./useLocalStorage";
import useRouter from "./useRouter";

export type CalendarViewSize = "day" | "week" | "month" | "year";
export type CalendarViewState = {
  view: string;
  viewSize: CalendarViewSize;
  showWeekends: boolean;
  startDate: string;
  endDate: string;
  style: 'expanded' | 'compact';
};

export const DAYS_OF_THE_WEEK = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
} as const;

const URL_PARAM_KEYS = ['style', 'showWeekends'] as const;

type CalendarSettingsProps = {
  calendarName: string;
  defaultViewState: CalendarViewState;
};

export const useCalendarSettings = ({
  calendarName,
  defaultViewState,
}: CalendarSettingsProps) => {
  const { updateParam } = useRouter();
  const [calendarSettings, setCalendarSettings] = useLocalStorage(
    calendarName,
    defaultViewState,
  );

  const syncUrlParams = useCallback((params: Record<string, string>) => {
    const currentParams = new URLSearchParams(window.location.search);
    
    URL_PARAM_KEYS.forEach((key) => {
      // If the URL param is not set, update it with the local storage value
      if (!currentParams.has(key) && params[key] !== undefined) {
        updateParam(key, params[key]);
      }
    });
  }, []);

  useEffect(() => {
    syncUrlParams(calendarSettings);
    localStorage.setItem(calendarName, JSON.stringify(calendarSettings));
  }, [calendarSettings]);

  return {
    calendarSettings,
    setCalendarSettings,
    setView: (view: CalendarViewSize) =>
      setCalendarSettings((prevSettings: CalendarViewState) => ({
        ...prevSettings,
        view,
        ...getDateBoundsForViewSize(view, prevSettings.startDate),
      })),
    setDate: (date: string | Date, viewSize: CalendarViewSize) =>
      // set start and end date based on viewSize
      setCalendarSettings((prevSettings: CalendarViewState) => {
        return {
          ...prevSettings,
          ...getDateBoundsForViewSize(viewSize, date),
        };
      }),
    toggleWeekends: () =>
      setCalendarSettings((prevSettings: CalendarViewState) => ({
        ...prevSettings,
        showWeekends: !prevSettings.showWeekends,
      })),
    goToPreviousWeek: () =>
      setCalendarSettings((prevSettings: CalendarViewState) => ({
        ...prevSettings,
        startDate: subDays(prevSettings.startDate, 7, { wee }).toISOString(),
        endDate: subDays(prevSettings.endDate, 7).toISOString(),
      })),
    goToNextWeek: () =>
      setCalendarSettings((prevSettings: CalendarViewState) => ({
        ...prevSettings,
        startDate: addDays(prevSettings.startDate, 7).toISOString(),
        endDate: addDays(prevSettings.endDate, 7).toISOString(),
      })),
    goToToday: () =>
      setCalendarSettings((prevSettings: CalendarViewState) => ({
        ...prevSettings,
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
      })),
    goToPreviousMonth: () =>
      setCalendarSettings((prevSettings: CalendarViewState) => ({
        ...prevSettings,
        startDate: subMonths(prevSettings.startDate, 1).toISOString(),
        endDate: subMonths(prevSettings.endDate, 1).toISOString(),
      })),
    goToNextMonth: () =>
      setCalendarSettings((prevSettings: CalendarViewState) => ({
        ...prevSettings,
        startDate: addMonths(prevSettings.startDate, 1).toISOString(),
        endDate: addMonths(prevSettings.endDate, 1).toISOString(),
      })),
    goToPreviousYear: () =>
      setCalendarSettings((prevSettings: CalendarViewState) => ({
        ...prevSettings,
        startDate: subYears(prevSettings.startDate, 1).toISOString(),
        endDate: subYears(prevSettings.endDate, 1).toISOString(),
      })),
    goToNextYear: () =>
      setCalendarSettings((prevSettings: CalendarViewState) => ({
        ...prevSettings,
        startDate: addYears(prevSettings.startDate, 1).toISOString(),
        endDate: addYears(prevSettings.endDate, 1).toISOString(),
      })),
  };
};

export const getDateBoundsForViewSize = (
  viewSize: CalendarViewSize,
  currentStartDate: string | Date,
) => {
  switch (viewSize) {
    case "day":
      return {
        startDate: startOfDay(new Date(currentStartDate)).toISOString(),
        endDate: endOfDay(new Date(currentStartDate)).toISOString(),
      };
    case "week":
      { const weekOptions: WeekOptions = {
        weekStartsOn: DAYS_OF_THE_WEEK.MONDAY,
      };
      return {
        startDate: startOfWeek(
          new Date(currentStartDate),
          weekOptions,
        ).toISOString(),
        endDate: endOfWeek(
          new Date(currentStartDate),
          weekOptions,
        ).toISOString(),
      }; }
    case "month":
      return {
        startDate: startOfMonth(new Date(currentStartDate)).toISOString(),
        endDate: endOfMonth(new Date(currentStartDate)).toISOString(),
      };
    case "year":
      return {
        startDate: startOfYear(new Date(currentStartDate)).toISOString(),
        endDate: endOfYear(new Date(currentStartDate)).toISOString(),
      };
  }
};

export const getViewSizeForFullCalendarView: (
  view: string,
) => CalendarViewSize = (view: string) => {
  switch (view) {
    case "dayGridMonth":
      return "month";
    case "dayGridWeek":
      return "week";
    case "timelineDay":
      return "day";
    default:
      return "week";
  }
};
