import React from "react";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";

import { queryClient } from "@/application";

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

type QueryProviderProps = {
  children: React.ReactNode;
  enableDevtools?: boolean;
};

export const QueryProvider = ({
  children,
  enableDevtools = false,
}: QueryProviderProps) => {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister: localStoragePersister }}
    >
      {children}
      {enableDevtools && <ReactQueryDevtools initialIsOpen={false} />}
    </PersistQueryClientProvider>
  );
};
