import { CraftworkersAvailability } from "@/types";
import {
  constructReactQuery,
  ConstructReactQueryConfig,
} from "@/utils/constructReactQuery";

type CraftworkerAvailabilityQueryProps = {
  start: string;
  end: string;
};

export const useCraftworkerAvailabilityQuery = (
  { start, end }: CraftworkerAvailabilityQueryProps,
  config?: ConstructReactQueryConfig<CraftworkersAvailability>,
) => {
  const url = `/api/calendars/craftworkers/availability?start=${start}&end=${end}`;
  return constructReactQuery<CraftworkersAvailability>(url, config);
};
