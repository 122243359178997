// Reconnect ActionCable after switching accounts

import { Controller } from "@hotwired/stimulus";

const WORK_HOURS_PER_DAY = 8;

export default class extends Controller {
  static targets = ["days", "hours"];

  updateHours() {
    const days = this.daysTarget.value;
    const hours = days * WORK_HOURS_PER_DAY;
    this.hoursTarget.value = hours;
  }
}
