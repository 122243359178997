import { Controller } from "@hotwired/stimulus";

const CABINET = "CabinetSet";
const NOT_APPLICABLE = "not_applicable";

export default class extends Controller {
  static values = {
    estimateId: String,
  };

  static targets = [
    "form",
    "area",
    "sheenField",
    "sheen",
    "sheenOptions",
    "itemType",
    "price",
    "priceField",
    "unitLabel",
    "free",
    "submitButtons",
    "optionalField",
    "selectedField",
    "optionalFieldCheckbox",
    "selectedFieldCheckbox",
  ];

  connect() {
    this.itemTypes = [];

    // Step 1: Fetch the list of supported item types for the area type
    this.changeArea();

    // Step 2:When the item type changes,
    //    (a) for cabinets: render the list of supported sheens for the given item type
    //    (b) fetch the price
    this.changeItemType();

    this.toggleSelectedField({ target: this.optionalFieldCheckboxTarget });
  }

  // Step 1: Select the area. This tells us which paintable type we're working with.
  // E.g. is it an interior area, an exterior area, or a cabinet set?
  //
  // When the area is selected, we fetch the list of supported item types for the given area type.
  async changeArea() {
    this.itemTypes = await this.fetchItemTypes();
    this.renderItemTypes();
    this.changeItemType();
  }

  async fetchItemTypes() {
    const response = await fetch(
      `/api/v1/item_types?paintable_type=${this.getAreaType()}&limit=100`,
    );
    return await response.json();
  }

  // Rerender the list of item types for the selected area type.
  renderItemTypes() {
    const currentItemTypeId = this.itemTypeTarget.value;

    this.itemTypeTarget.innerHTML = "";
    this.itemTypes.forEach((itemType) => {
      const option = new Option(itemType.name, itemType.id);
      if (itemType.id.toString() === currentItemTypeId) {
        option.selected = true;
      }
      this.itemTypeTarget.appendChild(option);
    });
  }

  // Step 2: Select the item type. This tells us which item type we're working with.
  // E.g. is it a cabinet, a door, a window, etc?
  //
  // When the item type changes,
  //    (a) for cabinets: render the list of supported sheens for the given item type
  //    (b) fetch the price
  async changeItemType() {
    // Always render the sheens so we can hide if the area type is not a cabinet anymore.
    this.renderSheens();

    await this.renderPrice();
  }

  // Render the list of supported sheens for the given item type.
  renderSheens() {
    const areaType = this.getAreaType();
    const paintSheenId = this.getPaintSheenId();

    // If it's not a cabinet, hide the sheen field.
    if (areaType !== CABINET) {
      this.sheenFieldTarget.classList.add("hidden");
      return;
    }

    // Get the list of supported sheens for the selected item type
    const sheens =
      this.itemTypes.find(
        (itemType) => itemType.id.toString() === this.itemTypeTarget.value,
      )?.supportedSheens || [];

    // If there are no supported sheens, hide the sheen field.
    if (sheens.length === 0) {
      this.sheenFieldTarget.classList.add("hidden");
      return;
    }

    // Show the sheen field and render the list of supported sheens
    this.sheenFieldTarget.classList.remove("hidden");

    // Render the list of supported sheen options
    this.sheenOptionsTarget.innerHTML = "";
    sheens.forEach((sheen) => {
      const option = new Option(sheen.name, sheen.id);
      if (sheen.id.toString() === paintSheenId) {
        option.selected = true;
      }
      this.sheenOptionsTarget.appendChild(option);
    });
  }

  // When the item type or sheen is selected, render the price
  async renderPrice() {
    const priceEstimate = await this.fetchPrice();

    // If there was no calculated price estimate returned from the API, clear the
    // calculated price.
    if (!priceEstimate) {
      this.calculatedPrice = null;
    } else {
      this.calculatedPrice = priceEstimate.amountInDollars;
    }

    // If there was a calculated price, set the placeholder to the calculated price.
    if (this.calculatedPrice) {
      this.priceTarget.placeholder = this.calculatedPrice.toString();

      // If the price field is the empty string or "0.0", clear the input and
      // set the placeholder to the calculated price.
      if (this.priceTarget.value === "" || this.priceTarget.value === "0.0") {
        this.priceTarget.value = "";
      }
    } else {
      // If there wasn't an item type selected, yet. Set the placeholder to
      // "Choose an item type". Otherwise, set the placeholder to "Specify a price".
      if (this.itemTypeTarget.value === "") {
        this.priceTarget.placeholder = "Choose an item type";
        this.priceTarget.value = "";
      } else {
        this.priceTarget.placeholder = "Specify a price";
      }
    }
  }

  // Validate the form
  validate() {
    const itemTypeId = this.itemTypeTarget.value;

    if (itemTypeId === "") {
      this.toggleDisabledSubmitButtons(true);
      return;
    }

    this.toggleDisabledSubmitButtons(false);
  }

  togglePriceField() {
    if (this.freeTarget.checked) {
      this.priceFieldTarget.classList.add("hidden");
    } else {
      this.priceFieldTarget.classList.remove("hidden");
    }
    this.validate();
  }

  toggleSelectedField(event) {
    const optionalChecked = event.target.checked;
    const selectedCheckbox = this.selectedFieldCheckboxTarget;
    const selectedHiddenField = selectedCheckbox.previousElementSibling;

    if (!optionalChecked) {
      selectedCheckbox.checked = true;
      selectedCheckbox.disabled = true;
      selectedHiddenField.value = "1"; // Set hidden field value to 1
    } else {
      selectedCheckbox.disabled = false;
      // When optional is checked, allow the selected checkbox to determine the value
      selectedHiddenField.value = selectedCheckbox.checked ? "1" : "0";
    }
  }

  updateSelectedValue() {
    const selectedCheckbox = this.selectedFieldCheckboxTarget;
    const selectedHiddenField = selectedCheckbox.previousElementSibling;
    selectedHiddenField.value = selectedCheckbox.checked ? "1" : "0";
  }

  setUnit() {
    if (this.itemTypeTarget.value.includes("sqft")) {
      this.unitLabelTarget.innerText = "SQFT";
      this.priceFieldTarget.querySelector("label").innerText = "Price per SQFT";
    } else {
      this.unitLabelTarget.innerText = "Quantity";
      this.priceFieldTarget.querySelector("label").innerText = "Price";
    }
  }

  getAreaType() {
    return this.areaTarget.options[this.areaTarget.selectedIndex].dataset
      .areaType;
  }

  getAreaSgid() {
    return this.areaTarget.options[this.areaTarget.selectedIndex].value;
  }

  getPaintSheenId() {
    if (this.sheenTarget.selectedIndex === -1) {
      return null;
    }

    return this.sheenTarget.options[this.sheenTarget.selectedIndex].value;
  }

  async fetchPrice() {
    const itemTypeId = this.itemTypeTarget.value;
    const paintableSgid = this.getAreaSgid();
    const paintSheenId = this.getPaintSheenId();

    if (itemTypeId === "") return null;

    try {
      const params = new URLSearchParams();

      params.set("itemTypeId", itemTypeId);
      params.set("estimateId", this.estimateIdValue);
      params.set("paintableSgid", paintableSgid);
      if (paintSheenId) {
        params.set("paintSheenId", paintSheenId);
      }

      const response = await fetch(
        `/api/v1/estimator/estimate_item?${params.toString()}`,
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Failed to fetch default price:", error);
      return null; // or handle the error as appropriate
    }
  }

  toggleDisabledSubmitButtons(validate) {
    this.submitButtonsTargets.forEach((button) => {
      button.disabled = validate;
    });
  }
}
