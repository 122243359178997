import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";

import TailwindConfig from "../../../tailwind.config.js";

// Connects to data-controller="bar-chart"
export default class extends Controller {
  connect() {
    this.ctx = this.element.getContext("2d");

    const datasets = [
      {
        label: this.ctx.canvas.dataset.label,
        data: JSON.parse(this.ctx.canvas.dataset.data),
        backgroundColor: TailwindConfig.theme.extend.colors.plum["DEFAULT"],
        borderColor: TailwindConfig.theme.extend.colors.plum["DEFAULT"],
        borderRadius: 0,
        borderWidth: 1,
      },
    ];

    if (this.ctx.canvas.dataset.label2) {
      datasets.push({
        label: this.ctx.canvas.dataset.label2,
        data: JSON.parse(this.ctx.canvas.dataset.data2),
        backgroundColor:
          this.ctx.canvas.dataset.color2 ||
          TailwindConfig.theme.extend.colors.blue[500],
        borderColor:
          this.ctx.canvas.dataset.color2 ||
          TailwindConfig.theme.extend.colors.blue[600],
        borderRadius: 0,
        borderWidth: 1,
      });
    }

    if (this.ctx.canvas.dataset.label3) {
      datasets.push({
        label: this.ctx.canvas.dataset.label3,
        data: JSON.parse(this.ctx.canvas.dataset.data3),
        backgroundColor: TailwindConfig.theme.extend.colors.green[500],
        borderColor: TailwindConfig.theme.extend.colors.green[700],
        borderRadius: 0,
        borderWidth: 1,
      });
    }

    if (this.ctx.canvas.dataset.label4) {
      datasets.push({
        label: this.ctx.canvas.dataset.label4,
        data: JSON.parse(this.ctx.canvas.dataset.data4),
        backgroundColor: TailwindConfig.theme.extend.colors.yellow[500],
        borderColor: TailwindConfig.theme.extend.colors.yellow[900],
        borderRadius: 0,
        borderWidth: 1,
      });
    }

    if (this.ctx.canvas.dataset.label5) {
      datasets.push({
        label: this.ctx.canvas.dataset.label5,
        data: JSON.parse(this.ctx.canvas.dataset.data5),
        backgroundColor: TailwindConfig.theme.extend.colors.blue[500],
        borderColor: TailwindConfig.theme.extend.colors.blue[900],
        borderRadius: 0,
        borderWidth: 1,
      });
    }

    this.chart = new Chart(this.ctx, {
      type: "bar",
      data: {
        labels: JSON.parse(this.ctx.canvas.dataset.labels),
        datasets: datasets,
      },
      options: {
        responsive: true,
        scales: {
          x: {
            stacked: true, // this should be set to make the bars stacked
          },
          y: {
            stacked: true, // this also..
          },
        },
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: this.ctx.canvas.dataset.title,
          },
        },
      },
    });
  }
}
