import React, { useState } from "react";
import {
  CodeBracketSquareIcon,
  PaperAirplaneIcon,
  PaperClipIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";

import { clsxm } from "../../utils/clsxm";

type ToggleProps = {
  isReply: boolean;
  setIsReply: (isReplying: boolean) => void;
};

const ResponseToggle = ({ isReply, setIsReply }: ToggleProps) => {
  const baseClasses =
    "block text-sm font-base-medium h-6 w-16 rounded-sm cursor-pointer";
  return (
    <div className="px-4 pb-2 pt-4">
      <div
        className={clsxm(
          "relative isolate flex w-max overflow-hidden rounded border",
          isReply ? "border-white" : "border-plum",
        )}
      >
        <button
          className={clsxm(
            baseClasses,
            isReply ? "text-white hover:bg-white/10" : " text-white",
          )}
          onClick={() => setIsReply(false)}
        >
          Note
        </button>
        <button
          className={clsxm(
            baseClasses,
            isReply ? " text-notification" : "text-gray-500 hover:bg-gray-50",
          )}
          onClick={() => setIsReply(true)}
        >
          Reply
        </button>
        <motion.span
          layout
          animate={{ x: isReply ? 64 : 0 }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
          className={clsxm(
            "absolute top-0 z-[-1] h-6 w-16 rounded-sm",
            isReply ? "bg-white" : "bg-plum",
          )}
        />
      </div>
    </div>
  );
};

const ActionBar = ({ isReply }: { isReply: boolean }) => {
  const actionBaseClasses =
    "flex items-center justify-center rounded-full h-8 w-8";
  const actionClasses = clsxm(
    actionBaseClasses,
    isReply ? "text-white hover:bg-white/10" : "text-plum hover:bg-gray-50",
  );

  return (
    <div className="flex items-center justify-between p-2">
      <div className="flex px-2">
        {isReply ? (
          <button className={actionClasses}>
            <CodeBracketSquareIcon className="h-5 w-5" />
          </button>
        ) : (
          <button className={actionClasses}>
            <PaperClipIcon className="h-5 w-5" />
          </button>
        )}
      </div>
      <button
        className={clsxm(
          "flex h-8 w-8 items-center justify-center rounded-full",
          isReply
            ? "bg-white text-notification hover:bg-white/80"
            : "bg-plum text-white hover:bg-plum/80",
        )}
      >
        <PaperAirplaneIcon className=" h-[18px] w-[18px]" />
      </button>
    </div>
  );
};

export const MessagesTextbox = () => {
  const [isReply, setIsReply] = useState(false);

  const adjustHeight = (e: {
    target: { style: { height: string }; scrollHeight: any };
  }) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  return (
    <div className="px-4 pb-4">
      <div
        className={clsxm(
          "rounded-md border",
          isReply
            ? "border-notification bg-notification text-white focus-within:ring-1 focus-within:ring-notification/50 focus-within:ring-offset-2"
            : "bg-white text-plum focus-within:ring-1 focus-within:ring-plum/50 focus-within:ring-offset-2",
        )}
      >
        <ResponseToggle isReply={isReply} setIsReply={setIsReply} />
        <textarea
          className={clsxm(
            "block max-h-40 min-h-[3em] w-full resize-none border-none bg-transparent px-4 py-2 text-sm focus-visible:ring-0",
            isReply
              ? "text-white placeholder-white/70"
              : "text-plum placeholder-plum/50",
          )}
          placeholder="Add some internal notes..."
          onChange={adjustHeight}
        />
        <ActionBar isReply={isReply} />
      </div>
    </div>
  );
};
