import React from "react";

import type { Resource } from "@/types";
import useRouter from "@/hooks/useRouter";
import { clsxm } from "@/utils/clsxm";

type ProjectResourceLabelProps = {
  resource: Resource;
};

export const ResourceLabel: React.FC<ProjectResourceLabelProps> = ({
  resource,
}) => {
  const {
    resourceName,
    url,
    projectType,
    totalEventDays,
    resourceType,
    customTitle,
  } = resource;
  const { params } = useRouter();
  const { style } = params;
  // Determine the color indicator based on the project type
  const indicatorColor = projectType ? `bg-${projectType}` : `bg-gray-200`;

  return (
    <div className="flex w-full gap-2 border-b border-r p-2 sticky left-0 bg-white z-10">
      <div className={`${style === 'compact' ? 'h-4' : 'h-10'} w-1 rounded ${indicatorColor}`}></div>
      <div className={clsxm("w-full overflow-hidden", style === 'compact' && 'max-h-6')}>
        <a
          href={url}
          className="flex flex-col gap-2 md:flex-row md:items-center"
        >
          <span className={clsxm("truncate font-base-bold", style === 'compact' && 'text-sm')}>{resourceName}</span>
        </a>

        {resourceType === "Project" && style !== 'compact' && (
          <div className="text-sm text-gray-600">
            <span className="font-base-medium">{totalEventDays}</span> days
            {customTitle && (
              <span className="font-base-medium"> - {customTitle}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
