import React from 'react';

import { Alert } from 'flowbite-react';
import { TrashTwo } from "@craftworkco/nucleo-icons";

type DiscardedAlertProps = { restoreUrl?: string, authenticityToken?: string }

export const DiscardedAlert:React.FC<DiscardedAlertProps> = ({ authenticityToken, restoreUrl }) => {

  return (
    <Alert rounded color="failure" className="w-full">
      <div className="flex items-center gap-2 justify-between w-full">
        <TrashTwo className="w-5 h-5" />
        <form action={restoreUrl} method="POST">
          <fieldset disabled> {/* disabled until there's a nicer way to handle this */}
            <input type="hidden" name="_method" value="patch" autoComplete="off" />
            <input type="hidden" name="authenticity_token" value={authenticityToken} autoComplete="off" />
            <input type="hidden" name="discarded_at" value="" autoComplete="off" />
            <span>This item was deleted.{' '}
              {/*
                {restoreUrl && (
                  <button className="bg-none border-none underline hover:text-black" disabled={false} type="submit">Restore</button>
                )}
              */}
            </span>
          </fieldset>
        </form>
      </div>
    </Alert>
  );
};
