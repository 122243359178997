import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "predefinedCrew",
    "individualPainter",
    "craftworkerIds",
    "eventId",
    "locationId",
  ];

  connect() {
    // Find the closest form ancestor to this.element and store it as an instance variable in the case of button_to
    this.form = this.element.closest("form");
    this.startDateInput = this.element.querySelector("#shift_starts_at");
    this.startDate = this.startDateInput ? this.startDateInput.value : null;
    if (this.form) {
      this.form.addEventListener(
        "turbo:submit-end",
        this.handleFormSubmit.bind(this),
      );
    } else {
      console.error("No form found for element:", this.element);
    }

    if (this.hasPreviousCrew()) {
      this.clearIndividualPainter();
    }

    this.predefinedCrewTarget.addEventListener(
      "change",
      this.clearIndividualPainter.bind(this),
    );
    this.individualPainterTarget.addEventListener(
      "change",
      this.clearPredefinedCrew.bind(this),
    );

    // Initial update of craftworkerIds
    this.updateCraftworkerIds();
  }

  disconnect() {
    // Remove the event listener from the form
    if (this.form) {
      this.form.removeEventListener(
        "turbo:submit-end",
        this.handleFormSubmit.bind(this),
      );
    }
  }

  hasPreviousCrew() {
    return (
      this.element.querySelector('optgroup[label="Previous Crew"]') !== null
    );
  }

  clearIndividualPainter() {
    this.individualPainterTarget.value = "";
    this.updateCraftworkerIds();
  }

  clearPredefinedCrew() {
    this.predefinedCrewTarget.value = "";
    this.updateCraftworkerIds();
  }

  updateCraftworkerIds() {
    const crewValue = this.predefinedCrewTarget.value;
    const painterValue = this.individualPainterTarget.value;

    let craftworkerIds = [];

    if (crewValue) {
      craftworkerIds = crewValue.split(",");
    } else if (painterValue) {
      craftworkerIds = [painterValue];
    }

    this.craftworkerIdsTarget.value = craftworkerIds.join(",");
  }

  updateEventAndLocation(event) {
    const [locationId, eventId] = event.target.value.split("-");
    this.locationIdTarget.value = locationId;
    this.eventIdTarget.value = eventId;
  }

  handleFormSubmit(event) {
    const { success } = event.detail;

    if (success) {
      try {
        if (window.queryClient) {
          window.queryClient.invalidateQueries({
            queryKey: ["/api/v1/calendar_events"],
          });
        } else {
          console.error("QueryClient not found on window");
        }
      } catch (e) {
        console.error("Error invalidating query:", e);
      }
    } else {
      console.error("Form submission failed");
    }
  }
}
