interface CalendarEventCompareProps {
  start: string | number;
  end: string | number;
  title: string;
  projectType: string;
}

export const compareProjectEvents = (
  a: CalendarEventCompareProps,
  b: CalendarEventCompareProps,
) => {
  const sortOrder = [
    "cabinets",
    "specialty",
    "commercial",
    "exterior",
    "interior",
  ];

  // Find the index of the event types in the sortOrder array
  let indexA = sortOrder.indexOf(a.projectType);
  let indexB = sortOrder.indexOf(b.projectType);

  // If the projectType is not found in the sortOrder, put it at the end
  if (indexA === -1) indexA = sortOrder.length;
  if (indexB === -1) indexB = sortOrder.length;

  // First, compare by projectType
  if (indexA !== indexB) {
    return indexA - indexB;
  }

  // Then, compare by startAt (earliest first)
  if (a.start < b.start) return -1;
  if (a.start > b.start) return 1;

  let durationA = new Date(a.end).getTime() - new Date(a.start).getTime();
  let durationB = new Date(b.end).getTime() - new Date(b.start).getTime();

  // Next, compare by duration (lowest first)
  if (durationA < durationB) return -1;
  if (durationA > durationB) return 1;

  // Finally, compare by title (a-z)
  if (a.title < b.title) return -1;
  if (a.title > b.title) return 1;

  // If all else is equal, maintain original order
  return 0;
};
