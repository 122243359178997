import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.updateHeightOnInput = this.updateHeight.bind(this);
    this.element.addEventListener("input", this.updateHeightOnInput);
    this.element.addEventListener("paste", this.updateHeightOnInput);
    this.element.addEventListener("cut", this.updateHeightOnInput);
    this.element.addEventListener("keydown", this.updateHeightOnInput);
  }

  disconnect() {
    this.element.removeEventListener("input", this.updateHeightOnInput);
    this.element.removeEventListener("paste", this.updateHeightOnInput);
    this.element.removeEventListener("cut", this.updateHeightOnInput);
    this.element.removeEventListener("keydown", this.updateHeightOnInput);
  }

  updateHeight() {
    // Logic to update the height of the text area
    this.element.style.height = "auto";
    this.element.style.height = `${this.element.scrollHeight}px`;
  }
}
