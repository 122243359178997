import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "toggle",
    "slider",
    "noteForm",
    "noteButton",
    "conversationRecord",
    "replyForm",
    "replyButton",
    "replyTextarea",
  ];

  static values = { projectId: Number, textBoxStates: Object };

  connect() {
    // Retrieve the textbox states from local storage
    const states = localStorage.getItem("projectTextboxStates");
    this.textboxStatesValue = states ? JSON.parse(states) : {};
    const projectId = this.projectIdValue;

    // Update UI based on the stored state or set to 'note' by default
    const theme = this.textboxStatesValue[projectId] || "note";
    this.updateUI(theme);
  }

  updateUI(theme) {
    const isNote = theme === "note";
    this.noteFormTarget.classList.toggle("hidden", !isNote);
    this.replyFormTarget.classList.toggle("hidden", isNote);
    this.conversationRecordTarget.classList.toggle("hidden", isNote);

    this.sliderTarget.classList.toggle("left-0", isNote);
    this.sliderTarget.classList.toggle("left-20", !isNote);

    this.noteButtonTarget.classList.toggle("text-white", isNote);
    this.noteButtonTarget.classList.toggle("text-gray-500", !isNote);

    this.replyButtonTarget.classList.toggle("text-white", !isNote);
    this.replyButtonTarget.classList.toggle("text-gray-500", isNote);
  }

  note() {
    this.updateUI("note");
    this.saveState("note");
  }

  reply() {
    this.updateUI("reply");
    this.saveState("reply");
  }

  saveState(theme) {
    const projectId = this.projectIdValue;

    // Update the state for the current project ID
    this.textboxStatesValue[projectId] = theme;

    // Save the updated states back to local storage
    localStorage.setItem(
      "projectTextboxStates",
      JSON.stringify(this.textboxStatesValue),
    );
  }
}
