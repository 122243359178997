import { useCraftworkerAvailabilityQuery } from "./useCraftworkerAvailabilityQuery";
import { useCalendarCraftworkersQuery } from "./useCalendarCraftworkersQuery";
import { useResourcesQuery } from "./useResourcesQuery";
import { useStaffingCalendarEventsQuery } from "./useStaffingCalendarEventsQuery";
import { useWeatherDaysQuery } from "./useWeatherDaysQuery";

type StaffingCalendarDataProps = {
  startDate: string;
  endDate: string;
};

export const useStaffingCalendarData = (
  { startDate, endDate }: StaffingCalendarDataProps,
) => {
  const { data: craftworkers } = useCalendarCraftworkersQuery({
    start: startDate,
    end: endDate,
  });

  const { data: craftworkersAvailability } = useCraftworkerAvailabilityQuery({
    start: startDate,
    end: endDate,
  });

  const { data: weatherDays } = useWeatherDaysQuery(
    {
      start: startDate,
      end: endDate,
    },
  );

  const { data: calendarEvents } = useStaffingCalendarEventsQuery(
    {
      start: startDate,
      end: endDate,
      options: {
        cabinet_shop_openings: true,
        collated: true,
        headquarters_openings: true,
        project_openings: true,
        shifts: true,
        timeoff_openings: true,
        timesheets: true,
      },
    },
  );

  const { data: resources } = useResourcesQuery(
    {
      start: startDate,
      end: endDate,
    },
  );

  return {
    weatherDays: weatherDays || [],
    calendarEvents: calendarEvents || [],
    resources: resources || [],
    craftworkersAvailability: craftworkersAvailability || {},
    craftworkers: craftworkers || [],
  };
};
