import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="conversation-text-box"
export default class extends Controller {
  static targets = [
    "fileField",
    "aiBtn",
    "preview",
    "previewWrapper",
    "attachmentPreviewWrapper",
    "clearButton",
    "textarea",
    "cannedResponsesList",
  ];

  static values = { id: Number };

  connect() {}

  setTextboxHeight() {
    const previewHeight = this.previewTarget.scrollHeight;
    const textareaHeight = this.textareaTarget.scrollHeight;

    if (previewHeight > textareaHeight) {
      this.textareaTarget.style.height = `${previewHeight}px`;
    } else {
      this.previewTarget.style.height = `${textareaHeight}px`;
    }
  }

  dropAttachment(event) {
    if (!event.dataTransfer.files.length) return;
    event.preventDefault();
    this.handleAttachedFiles(event.dataTransfer.files);
  }

  pasteAttachment(event) {
    if (!event.clipboardData.files.length) return;
    event.preventDefault();
    this.handleAttachedFiles(event.clipboardData.files);
  }

  fileAttached(event) {
    // the handleAttachedFiles method will handle
    // the files from the file input already, so we pass
    // an empty array here to avoid duplicating the files.
    this.handleAttachedFiles([]);
  }

  handleAttachedFiles(files) {
    const newFiles = Array.from(files);
    const currentFiles = Array.from(this.fileFieldTarget.files);
    const allFiles = [...currentFiles, ...newFiles];

    // FileList is immutable, so we need to create a new DataTransfer object
    // and add the files to it so that we can build a FileList
    // that we can use to set the value on the file input.
    const dataTransfer = new DataTransfer();
    allFiles.forEach((file) => dataTransfer.items.add(file));
    this.fileFieldTarget.files = dataTransfer.files;

    // Update the UI to show the attachments
    this.attachmentPreviewWrapperTarget.innerHTML = "";
    this.clearButtonTarget.classList.remove("hidden");
    this.previewWrapperTarget.classList.remove("hidden");
    this.previewWrapperTarget.classList.add("flex");

    allFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (e) => {
        if (file.type.includes("image")) {
          this.createImageAttachment(e);
        } else {
          this.createFileAttachment(file);
        }
      };
    });
  }

  clearAttachments(event) {
    // Clear the file input; resetting its value
    this.fileFieldTarget.value = "";
    this.clearButtonTarget.classList.add("hidden");
    this.previewWrapperTarget.classList.remove("flex");
    this.previewWrapperTarget.classList.add("hidden");
    // Remove all child nodes from the attachmentPreviewWrapper
    while (this.attachmentPreviewWrapperTarget.firstChild) {
      this.attachmentPreviewWrapperTarget.removeChild(
        this.attachmentPreviewWrapperTarget.firstChild,
      );
    }
  }

  createImageAttachment(e) {
    const attachmentPreview = document.createElement("img");
    attachmentPreview.classList.add("w-[100px]", "rounded-md", "border");
    attachmentPreview.src = e.target.result;
    this.attachmentPreviewWrapperTarget.appendChild(attachmentPreview);
  }

  createFileAttachment(file) {
    const attachmentPreview = document.createElement("div");
    attachmentPreview.classList.add(
      "flex",
      "gap-2",
      "items-center",
      "bg-white",
      "rounded-md",
      "p-2",
      "overflow-hidden",
      "text-sm",
      "truncate",
    );
    attachmentPreview.innerHTML = `${this.attachmentIcon()} ${file.name}`;
    this.attachmentPreviewWrapperTarget.appendChild(attachmentPreview);
  }

  attachmentIcon() {
    return `
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m6.75 12-3-3m0 0-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
    </svg>
    `;
  }

  toggleCannedResponses() {
    this.cannedResponsesListTarget.classList.toggle("hidden");
  }

  selectCannedResponse(event) {
    const response = event.currentTarget.dataset.response;
    this.textareaTarget.value += response; // Append selected response to current text
    // Hide the UI after selection
    this.toggleCannedResponses();
    // Optionally, focus the text area and trigger any input events
    this.textareaTarget.focus();
    this.textareaTarget.dispatchEvent(new Event("input"));
  }

  // -- CONVERSATION COPILOT --
  // When typing a message to a customer, we generate possible completions
  // based on customer context and the conversation history.
  //
  // This is done when the user stops typing for a short period of time:
  // * API call to /api/v1/bot_responses
  // * That calls the AI::Service#chat method under the hood
  // * The AI::Service determines which AI provider and model to use for the prediction
  // * The AI provider generates a response based on the conversation
  // * The response is returned to the client
  // * We set the text content of a "preview" tab which is z-index 5 and sits on top of the textarea
  // * If the user types a <TAB>, the preview content is appended to the textarea.
  // * If the user types anything else, the preview content is removed and a new prediction is generated.

  // Called when someone clicks the AI button
  //
  // Generate a full prediction and update the textareaTarget value to use
  // the new completion. In this case we don't pass a max_tokens parameter, so
  // the default value of 4096 is used.
  async generateCopilot() {
    this.aiBtnTarget.classList.add("trip-animation");
    const draft = this.textareaTarget.value;
    try {
      const completion = await this.predictCopilot({ max_tokens: 300 });
      if (completion) {
        this.textareaTarget.value = `${draft} ${completion}`;
      } else {
        console.error("No completion generated", completion);
      }
    } catch (error) {
      console.error("Error:", error);
      this.aiBtnTarget.classList.remove("trip-animation");
    }
    this.aiBtnTarget.classList.remove("trip-animation");
  }

  // DISABLED in conversation_textbox_component.html.erb
  // Context: Not used enough, getting in the way, and costing $$$.
  // See CRA-4725
  //
  // Called when the user types in the textarea
  async copilotPlaceholder() {
    const draft = this.textareaTarget.value;
    this.completion = "";
    this.previewTarget.textContent = "";

    if (draft.length < 2) {
      clearTimeout(this.debounceTimer);
      return;
    }

    this.debounce(async () => {
      this.completion = await this.predictCopilot({ max_tokens: 50 });

      if (this.completion) {
        this.previewTarget.textContent = `${draft}${this.completion}`;
      } else {
        this.previewTarget.textContent = "";
      }

      this.setTextboxHeight();

      this.lastDraft = draft;
      this.lastCompletion = this.completion;
    }, 300);
  }

  // Called on keydown of the textarea, but filters to just the TAB key.
  applyPrediction(e) {
    if (e.key === "Tab") {
      e.preventDefault();

      if (this.completion) {
        this.previewTarget.textContent = "";
        this.textareaTarget.value = `${this.textareaTarget.value}${this.completion}`;
        this.completion = "";
      }
    }
  }

  // Used by both copilotPlaceholder and generateCopilot to call the API
  //
  // Fire the API call to our server to generate a conversation copilot
  // response. This accepts a params object with `max_tokens` which is
  // used to determine the amount of text to generate.
  //
  // Example usage:
  //   params: { max_tokens: 50 }
  async predictCopilot(params = {}) {
    const draft = this.textareaTarget.value;
    try {
      const response = await fetch("/api/v1/bot_responses", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...params,
          draft,
          conversation_id: this.idValue,
        }),
      });
      if (response && response.ok) {
        const data = await response.json();
        return data.body;
      }
    } catch (error) {
      return draft;
    }
  }

  // Helper function to debounce the callback to update the user's settings
  debounce(callback, delay = 600) {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(callback, delay);
  }

  // -- END CONVERSATION COPILOT --
}
