import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="exterior-area-form"
export default class extends Controller {
  static targets = ["type", "name"];

  connect() {}

  typeSelected() {
    if (this.nameTarget.value == "") {
      this.nameTarget.value =
        this.typeTarget.options[this.typeTarget.selectedIndex].text;
      this.nameTarget.focus();
    }
  }
}
