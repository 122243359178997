import type { CalendarCraftworker } from "@/types";

export const getEnglishSpeakers = (craftworkers: CalendarCraftworker[]) => {
  return craftworkers.filter((cw) => cw.languages.includes("en"));
};

export const getEnglishSpeakersString = (craftworkers: CalendarCraftworker[]) => {
  return craftworkers
    .filter((cw) => cw.languages.includes("en"))
    .map((cw, i, arr) => {
      if (i === 0) return cw.firstName;
      if (i === arr.length - 1) return ` and ${cw.firstName}`;
      return `, ${cw.firstName}`;
    })
    .join("");
};

export const getSprayers = (craftworkers: CalendarCraftworker[]) => {
  return craftworkers.filter((cw) => cw.level.includes("level_3"));
};

export const getSprayersString = (craftworkers: CalendarCraftworker[]) => {
  return craftworkers
    .filter((cw) => cw.level.includes("level_3"))
    .map((cw, i, arr) => {
      if (i === 0) return cw.firstName;
      if (i === arr.length - 1) return ` and ${cw.firstName}`;
      return `, ${cw.firstName}`;
    })
    .join("");
};
