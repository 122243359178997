import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static values = {
    url: String,
  };

  connect() {
    this.select = new TomSelect(this.element, {
      valueField: "id",
      labelField: "name",
      searchField: ["name", "email", "phone"],
      render: {
        item: (data, escape) => {
          return `<div>${data.name} - ${data.phone}</div>`;
        },
        option: (data, escape) => {
          return `<div>${data.name} - ${data.phone}</div>`;
        },
      },
      load: (query, callback) => {
        const url = `${this.urlValue}?q=${encodeURIComponent(query)}`;
        fetch(url)
          .then((response) => response.json())
          .then((json) => {
            callback(json);
          })
          .catch(() => {
            callback();
          });
      },
    });
  }

  disconnect() {
    this.select.destroy();
  }
}
