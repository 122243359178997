import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="gallery"
export default class extends Controller {
  static targets = ["attachment", "count"];
  static values = { index: Number };

  connect() {
    this.currentIndex = this.indexValue || 0;
    this.updateView();
    this.handleKeyEvents = this.handleKeyEvents.bind(this);
    document.addEventListener("keydown", this.handleKeyEvents);
    this.element.addEventListener(
      "touchstart",
      this.handleTouchStart.bind(this),
    );
    this.element.addEventListener("touchmove", this.handleTouchMove.bind(this));
    this.element.addEventListener("touchend", this.handleTouchEnd.bind(this));
    this.prefetch();
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleKeyEvents);
    this.element.removeEventListener("touchstart", this.handleTouchStart);
    this.element.removeEventListener("touchmove", this.handleTouchMove);
    this.element.removeEventListener("touchend", this.handleTouchEnd);
  }

  handleTouchStart(event) {
    this.touchStartX = event.touches[0].clientX;
  }

  handleTouchMove(event) {
    this.touchEndX = event.touches[0].clientX;
  }

  handleTouchEnd(event) {
    if (this.touchStartX - this.touchEndX > 50) {
      this.next();
    }
    if (this.touchStartX - this.touchEndX < -50) {
      this.previous();
    }
  }

  handleKeyEvents(event) {
    switch (event.key) {
      case "ArrowRight":
      case "l":
        this.next();
        event.preventDefault();
        break;
      case "ArrowLeft":
      case "h":
        this.previous();
        event.preventDefault();
        break;
    }
  }

  next() {
    this.currentIndex = (this.currentIndex + 1) % this.attachmentTargets.length;
    this.updateView();
  }

  previous() {
    this.currentIndex =
      (this.currentIndex - 1 + this.attachmentTargets.length) %
      this.attachmentTargets.length;
    this.updateView();
  }

  updateView() {
    this.attachmentTargets.forEach((attachment, index) => {
      attachment.classList.toggle("hidden", index !== this.currentIndex);
    });
    this.countTarget.textContent = `${this.currentIndex + 1} / ${this.attachmentTargets.length}`;
  }

  prefetch() {
    this.attachmentTargets.forEach((attachment) => {
      const media = attachment.querySelector("img, video");
      if (media) {
        const src = media.src || media.querySelector("source").src;
        const link = document.createElement("link");
        link.rel = "prefetch";
        link.href = src;
        document.head.appendChild(link);
      }
    });
  }
}
