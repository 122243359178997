// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import AccordionController from "@kanety/stimulus-accordion";
import Flatpickr from "stimulus-flatpickr";
import Notification from "stimulus-notification";
import {
  Modal,
  Popover,
  Slideover,
  Tabs,
  Toggle,
} from "tailwindcss-stimulus-components";

// Register each controller with Stimulus
import controllers from "./**/*_controller.js";
import { application } from "./application";
import DropdownController from "./dropdown_controller";
import TabsController from "./tabs_controller";

controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default);
});

application.register("modal", Modal);
application.register("popover", Popover);
application.register("toggle", Toggle);
application.register("slideover", Slideover);
application.register("notification", Notification);

application.register("flatpickr", Flatpickr);

application.register("accordion", AccordionController);

application.register("tabs", TabsController);

application.register("dropdown", DropdownController);
