/**
 * This file registers a service worker for push notifications.
 * It also asks the user for permission to send notifications.
 */
const registerPushNotificationServiceWorker = async () => {
  // Check if the browser supports service workers
  if (!("serviceWorker" in navigator)) {
    console.error("Service workers are not supported in this browser");
    return;
  }

  if (!("PushManager" in window)) {
    console.error("Push notifications are not supported in this browser");
    return;
  }

  if (!("Notification" in window)) {
    console.error("Desktop notifications are not supported in this browser");
    return;
  }

  switch (Notification.permission) {
    case "granted":
      break;
    case "denied":
      return; // leave this function and don't register the notification
    case "default":
    default:
      Notification.requestPermission(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          // got it
        } else {
          return; // leave this function and don't register the notification
        }
      });
  }

  // Register the service worker
  // This will also ask the user for permission to send notifications
  try {
    const reg = await navigator.serviceWorker.register("/service_worker.js");
    const serviceWorkerRegistration = await navigator.serviceWorker.ready;
    const subscription = await serviceWorkerRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: window.vapidPublicKey,
    });

    // Send the subscription to the server
    const sub = JSON.parse(JSON.stringify(subscription));
    const response = await fetch("/api/v1/webpush_subscriptions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        webpush_subscription: {
          endpoint: sub.endpoint,
          p256dh: sub.keys.p256dh,
          auth: sub.keys.auth,
        },
      }),
    });

    // Log the response
    const webpushSubscription = await response.json();
  } catch (error) {
    console.error("Error registering service worker:", error);
  }
};

module.exports = registerPushNotificationServiceWorker;
