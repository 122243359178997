import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["shiftId", "locationId", "eventId"];

  connect() {
    // Find the closest form ancestor to this.element and store it as an instance variable in the case of button_to
    this.form = this.element.closest("form");
    if (this.form) {
      this.form.addEventListener(
        "turbo:submit-end",
        this.handleFormSubmit.bind(this),
      );
    } else {
      console.error("No form found for element:", this.element);
    }
  }

  updateShiftAndLocation(event) {
    const [locationId, eventId, shiftId] = event.target.value.split("-");
    this.locationIdTarget.value = locationId;
    this.shiftIdTarget.value = shiftId;
    this.eventIdTarget.value = eventId;
  }

  handleFormSubmit(event) {
    const { success } = event.detail;

    if (success) {
      try {
        if (window.queryClient) {
          window.queryClient.invalidateQueries({
            queryKey: ["/api/v1/calendar_events"],
          });
        } else {
          console.error("QueryClient not found on window");
        }
      } catch (e) {
        console.error("Error invalidating query:", e);
      }
    } else {
      console.error("Form submission failed");
    }
  }
}
