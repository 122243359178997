import React from "react";
import { Select } from "flowbite-react";

import { EstimateShow } from "@/components/estimates/EstimateShow";
import {
  EstimatesList,
  EstimatesListSkeleton,
} from "@/components/estimates/EstimatesList";
import {
  Main,
  Sidebar,
  SidebarLayout,
} from "@/components/layouts/SidebarLayout";
import { useCraftworkersQuery } from "@/hooks/useCraftworkersQuery";
import { useEstimatesQuery } from "@/hooks/useEstimatesQuery";
import useRouter from "@/hooks/useRouter";
import { RootProvider } from "@/providers/RootProvider";

const ListFilters = () => {
  const { updateParam, params } = useRouter();
  const selectedManagerId = params.managerId;

  const { data: accountManagers } = useCraftworkersQuery({
    by_role: ["account_manager"],
  });

  const handleManagerSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    if (value === "") {
      updateParam("managerId", null);
    } else {
      updateParam("managerId", value);
    }
  };

  return (
    <div className="flex justify-between p-2">
      <Select
        className="flex-1"
        value={selectedManagerId || ""}
        onChange={handleManagerSelect}
      >
        <option value="">All Account Managers</option>
        {accountManagers?.map((manager, i) => (
          <option key={i} value={manager.userId}>
            {manager.user.name}
          </option>
        ))}
      </Select>
    </div>
  );
};

const List = () => {
  const { params } = useRouter();
  const managerId = params.managerId;

  const { data: estimates, isLoading } = useEstimatesQuery({
    status: ["internalReview", "internallyApproved"],
    managerId: managerId || undefined,
  });

  return (
    <Sidebar className="divide-y">
      <ListFilters />
      {estimates ? (
        <EstimatesList estimates={estimates} isLoading={isLoading} />
      ) : (
        <EstimatesListSkeleton />
      )}
    </Sidebar>
  );
};

const Show = () => {
  const { params } = useRouter();
  const estimateId = params.estimateId || "";

  return (
    <Main>
      <EstimateShow estimateId={estimateId} />
    </Main>
  );
};

export const SalesEstimatesReviewIndex = () => {
  return (
    <RootProvider>
      <SidebarLayout sidebar={<List />} main={<Show />} />
    </RootProvider>
  );
};
