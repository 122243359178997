import type { Craftworker } from "@/types";
import type {
  ConstructReactQueryConfig} from "@/utils/constructReactQuery";
import {
  constructReactQuery
} from "@/utils/constructReactQuery";

type CraftworkersQueryProps = {
  by_role?: string[];
};

export const useCraftworkersQuery = (
  { by_role }: CraftworkersQueryProps,
  config?: ConstructReactQueryConfig<Craftworker[]>,
) => {
  const searchParams = new URLSearchParams();

  if (by_role) {
    by_role.forEach((r) => searchParams.append("by_role[]", r));
  }

  const url = `/api/v1/craftworkers?${searchParams.toString()}`;
  return constructReactQuery<Craftworker[]>(url, config);
};
