import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["header", "body", "icon"];

  toggle() {
    if (this.bodyTarget.classList.contains("grid-rows-[0fr]")) {
      this.bodyTarget.classList.remove("grid-rows-[0fr]");
      this.bodyTarget.classList.add("grid-rows-[1fr]");
      this.iconTarget && this.iconTarget.classList.add("rotate-180");
    } else {
      this.bodyTarget.classList.remove("grid-rows-[1fr]");
      this.bodyTarget.classList.add("grid-rows-[0fr]");
      this.iconTarget && this.iconTarget.classList.remove("rotate-180");
    }
  }
}
