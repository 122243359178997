import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="project-form"
export default class extends Controller {
  static targets = ["title"];

  connect() {}

  changeType(e) {
    const projectType =
      e.currentTarget.options[e.currentTarget.selectedIndex].text;
    if (this.titleTarget.value === "") {
      this.titleTarget.value = projectType;
    }
  }
}
