import React from "react";
import {
  Bar,
  CartesianGrid,
  BarChart as RechartsBarChart,
  ReferenceLine,
  XAxis,
  YAxis,
} from "recharts";
import type { ReferenceLineProps } from "recharts/types";
import type { CategoricalChartProps } from "recharts/types/chart/generateCategoricalChart";

import { clsxm } from "@/utils/clsxm";

import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "./Chart";
import type { ChartConfig } from "./Chart";
import type { AxisDomain } from "recharts/types/util/types";

type BarChartProps<T> = CategoricalChartProps & {
  data: T[];
  config: ChartConfig;
  xAxisDataKey?: string;
  yAxisDataKey?: string;
  showLegend?: boolean;
  stacked?: boolean;
  className?: string;
  reference?: ReferenceLineProps;
  barRadius?: number | [number, number, number, number];
  yAxisDomain?: AxisDomain;
};

export function BarChart<T>({
  data,
  config,
  xAxisDataKey,
  yAxisDataKey,
  showLegend,
  stacked,
  className,
  reference,
  barRadius = [4, 4, 0, 0],
  yAxisDomain = ["dataMin", "dataMax"],
  ...restBarChartProps
}: BarChartProps<T>) {
  const { ref: _ref, ...restReferencesProps } = reference || {};
  return (
    <ChartContainer
      config={config}
      className={clsxm("min-h-[200px] w-full", className)}
    >
      <RechartsBarChart accessibilityLayer data={data} {...restBarChartProps}>
        <CartesianGrid
          vertical={false}
          strokeDasharray="3 3"
          fillOpacity={0.6}
        />
        <XAxis
          dataKey={xAxisDataKey}
          tickLine={false}
          tickMargin={10}
          axisLine={false}
        />
        <YAxis
          dataKey={yAxisDataKey}
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          domain={yAxisDomain}
        />
        <ChartTooltip content={<ChartTooltipContent />} />
        {reference && <ReferenceLine {...restReferencesProps} />}
        {showLegend && <ChartLegend content={<ChartLegendContent />} />}
        {Object.keys(config).map((key) => (
          <Bar
            key={key}
            dataKey={key}
            fill={config[key]?.color}
            stackId={stacked ? 1 : 0}
            radius={barRadius}
          />
        ))}
      </RechartsBarChart>
    </ChartContainer>
  );
}
