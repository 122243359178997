import React from "react";

import consumer from "../../channels/consumer";
import {
  AuditMessage,
  CommentMessage,
  EmailMessage,
  TextMessage,
} from "./Messages";

export const MessagesFeed = ({
  commentableId,
  commentableType,
  conversationId,
  messages: initialMessages,
}) => {
  const listRef = React.useRef(null);
  const [messages, setMessages] = React.useState(initialMessages);

  const [scrollHeight, setScrollHeight] = React.useState(0);

  const updateScrollHeight = () => {
    if (!listRef.current) return;

    if (listRef.current.scrollHeight !== scrollHeight) {
      setScrollHeight(listRef.current.scrollHeight);
      listRef.current.scrollTop = listRef.current.scrollHeight;
      return;
    }
  };

  React.useEffect(() => {
    updateScrollHeight();
  }, [messages]);

  // Listen for and update the feed when there are new Comments!
  React.useMemo(() => {
    const subscription = consumer.subscriptions.create(
      {
        channel: "CommentChannel",
        commentable_id: commentableId,
        commentable_type: commentableType,
      },
      {
        received(comment) {
          setMessages((oldMessages) => [...oldMessages, comment]);
        },
      },
    );

    return () => {
      consumer.subscriptions.remove(subscription);
    };
  }, [commentableId, commentableType]);

  // Listen for and update the feed when there are new Comments!
  React.useMemo(() => {
    const subscription = consumer.subscriptions.create(
      {
        channel: "ConversationChannel",
        conversation_id: conversationId,
      },
      {
        received(message) {
          setMessages((oldMessages) => [...oldMessages, message]);
        },
      },
    );

    return () => {
      consumer.subscriptions.remove(subscription);
    };
  }, [conversationId]);

  return (
    <ul
      ref={listRef}
      className="flex h-full shrink-0 flex-col gap-6 overflow-y-auto p-4"
    >
      {messages.map((message) => {
        if ("commentableId" in message) {
          return <CommentMessage message={message} key={message.id} />;
        }
        if ("channel" in message && message.channel === "email") {
          return <EmailMessage message={message} key={message.id} />;
        }
        if ("channel" in message && message.channel === "sms") {
          return <TextMessage message={message} key={message.id} />;
        }
        if ("channel" in message && message.channel === "podium") {
          return <TextMessage message={message} key={message.id} />;
        }
        if ("channel" in message && message.channel === "voice") {
          return <TextMessage message={message} key={message.id} />;
        }
        if ("channel" in message && message.channel === "web_chat") {
          return <TextMessage message={message} key={message.id} />;
        }
        if ("channel" in message && message.channel === "facebook") {
          return <TextMessage message={message} key={message.id} />;
        }
        if ("channel" in message && message.channel === "yelp") {
          return <TextMessage message={message} key={message.id} />;
        }
        if (Array.isArray(message)) {
          return <AuditMessage message={message} key={message[0].id} />;
        }
      })}
    </ul>
  );
};
