import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";

// Connects to data-controller="timeseries-chart"

export default class extends Controller {
  static values = {
    data: Object,
    aggregation: String,
  };

  connect() {
    const ctx = this.element.getContext("2d");

    const chartData = this.prepareData(this.dataValue);
    const chartOptions = this.chartOptions();

    new Chart(ctx, {
      type: "line", // This can be changed to 'bar' for a bar chart
      data: {
        labels: chartData.labels,
        datasets: [
          {
            label: `Hours by ${this.aggregationValue}`,
            backgroundColor: "rgba(54, 162, 235, 0.5)",
            borderColor: "rgb(54, 162, 235)",
            data: chartData.data,
          },
        ],
      },
    });
  }

  prepareData(data) {
    const labels = [];
    const dataset = [];

    Object.entries(data).forEach(([key, value]) => {
      labels.push(key);
      dataset.push(value);
    });

    return { labels, data: dataset };
  }

  chartOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: "time",
          time: {
            unit: this.aggregationValue,
          },
          title: {
            display: true,
            text: "Date",
          },
        },
        y: {
          title: {
            display: true,
            text: "Hours",
          },
        },
      },
    };
  }
}
