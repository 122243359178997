import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sales-statuses"
export default class extends Controller {
  static targets = ["lostForm", "holdForm"];

  connect() {}

  toggleLostForm(e) {
    e.preventDefault();
    this.lostFormTarget.classList.toggle("hidden");
  }

  toggleHoldForm(e) {
    e.preventDefault();
    this.holdFormTarget.classList.toggle("hidden");
  }
}
