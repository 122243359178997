import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="custom-view-form"
export default class extends Controller {
  static targets = ["form"];

  connect() {}

  toggle() {
    this.formTarget.classList.toggle("hidden");
  }
}
