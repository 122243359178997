// Import the Controller class from Stimulus
import { Controller } from "@hotwired/stimulus";

// Define a new class that extends Controller
export default class extends Controller {
  static targets = ["button"];

  static values = {
    lockId: String,
  };

  // Connect method to initialize or setup the controller
  connect() {
    // Add a click event listener to the element this controller is attached to
  }

  // Define the unlock method
  async toggle(event) {
    // event.preventDefault();

    // disable the button while we wait
    this.buttonTarget.disabled = true;

    // re-enable the button
    this.buttonTarget.disabled = false;
  }
}
