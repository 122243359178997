import React from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { motion } from "framer-motion";
import TimeAgo, { TDate } from "timeago-react";

import { clsxm } from "../../utils/clsxm";

type MessageContainerProps = {
  alignLeft?: boolean;
  date?: TDate;
  avatarImage: string;
  avatarAlt: string;
  messageType: string;
  fullWidth?: boolean;
  inbound?: boolean;
  children: React.ReactNode;
};

const MessageContainer = ({
  alignLeft,
  date,
  avatarImage,
  avatarAlt,
  messageType,
  fullWidth,
  inbound,
  children,
}: MessageContainerProps) => {
  return (
    <li
      className={clsxm(
        "flex text-sm",
        alignLeft ? "justify-start pr-20" : "justify-end pl-20",
      )}
    >
      <div
        className={clsxm(
          "flex max-w-[600px] flex-col gap-1",
          alignLeft ? "items-start" : "items-end",
          fullWidth && "w-full",
        )}
      >
        <div
          className={clsxm(
            "whitespace-nowrap text-xs text-gray-500",
            alignLeft ? "ml-[60px]" : "mr-[60px]",
          )}
        >
          {date && <TimeAgo datetime={date} />}
        </div>
        <div className="flex w-full gap-4">
          {alignLeft && <Avatar src={avatarImage} alt={avatarAlt} />}
          <div
            className={clsxm(
              "w-full overflow-hidden rounded-md p-2 [word-break:break-word]",
              (messageType === "comment" || inbound) && "bg-gray-50 text-plum",
              messageType === "email" && !inbound && "bg-interior text-white",
              messageType === "text" && !inbound && "bg-interior text-white",
              messageType === "audit" && "border bg-white text-plum",
            )}
          >
            {children}
          </div>
          {!alignLeft && <Avatar src={avatarImage} alt={avatarAlt} />}
        </div>
      </div>
    </li>
  );
};

type AvatarProps = {
  src: string;
  alt: string;
};

const Avatar = ({ src, alt }: AvatarProps) => {
  const initials = alt
    .split(" ")
    .map((name) => name[0])
    .join("");
  return (
    <div className="flex h-10 w-10 shrink-0 items-center justify-center overflow-hidden rounded-full border">
      {(src === "customerAvatar" || src.includes("gravatar")) && (
        <div className="flex h-8 w-8 items-center justify-center overflow-hidden rounded-full bg-plum/50 pt-0.5 font-base-bold leading-none text-white">
          {initials}
        </div>
      )}

      {src === "defaultAvatar" && (
        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-plum text-white">
          <svg
            width="20"
            height="20"
            viewBox="0 0 270 270"
            fill="currentColor"
            className="mb-0.5"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M270 194.477H77.985V129.841L151.087 87.6211L270 153.473V65.9064L150.975 0L0 87.1652V270H270V194.477Z"
            />
          </svg>
        </div>
      )}

      {src !== "customerAvatar" &&
        src !== "defaultAvatar" &&
        !src.includes("gravatar") &&
        src !== "" && <img src={src} width={40} height={40} alt={alt} />}
    </div>
  );
};

export const CommentMessage = ({ message }) => {
  return (
    <MessageContainer
      date={message.createdAt}
      avatarImage={message.userAvatar}
      avatarAlt={message.userName}
      messageType="comment"
    >
      <div className="mb-2 font-base-medium">{message.userName}</div>
      <div dangerouslySetInnerHTML={{ __html: message.body }} />
    </MessageContainer>
  );
};

export const EmailMessage = ({ message }) => {
  return (
    <MessageContainer
      alignLeft={message.direction === "inbound"}
      date={message.createdAt}
      avatarImage={
        message.direction === "inbound" ? "customerAvatar" : message.userAvatar
      }
      avatarAlt={message.userName}
      messageType="email"
      inbound={message.direction === "inbound"}
      fullWidth={true}
    >
      <div className="mb-2 truncate font-base-bold">{message.subject}</div>
      <div className="mb-2 flex flex-col">
        <div className="flex gap-2">
          <p className="font-base-medium">From:</p>
          <ul className="flex flex-col">
            {message.from.map((from, idx) => (
              <li key={idx}>{from}</li>
            ))}
          </ul>
        </div>
        <div className="flex gap-2">
          <p className="font-base-medium">To:</p>
          <ul className="flex flex-col">
            {message.to.map((to, idx) => (
              <li key={idx}>{to}</li>
            ))}
          </ul>
        </div>
      </div>
      {message.htmlBody && (
        <div className="w-full overflow-hidden rounded bg-white text-plum">
          <p className="p-4">{message.body}</p>
        </div>
      )}
    </MessageContainer>
  );
};

export const TextMessage = ({ message }) => {
  return (
    <MessageContainer
      alignLeft={message.direction === "inbound"}
      date={message.created_at}
      avatarImage={
        message.direction === "inbound" ? "customerAvatar" : "defaultAvatar"
      }
      avatarAlt={message.userName}
      messageType="text"
      inbound={message.direction === "inbound"}
    >
      <div className="mb-2 flex flex-col">
        <div className="flex gap-2">
          <p className="font-base-medium">From:</p>
          <ul className="flex flex-col">
            {message.from.map((from, idx) => (
              <li key={idx}>
                {from} <em>via</em>{" "}
                <span className="capitalize">{message.channel}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex gap-2">
          <p className="font-base-medium">To:</p>
          <ul className="flex flex-col">
            {message.to.map((to, idx) => (
              <li key={idx}>{to}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="font-base-medium">{message.body}</div>
    </MessageContainer>
  );
};

export const AuditMessage = ({ message }) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <MessageContainer
      alignLeft={false}
      avatarImage="defaultAvatar"
      avatarAlt="System"
      messageType="audit"
      fullWidth={true}
    >
      <button
        onClick={() => setExpanded(!expanded)}
        className="flex w-full items-center justify-between px-2 text-gray-300"
      >
        <span className="font-base-bold leading-none">
          {message.length} Project Update{message.length > 1 && "s"}
        </span>
        <motion.span animate={{ rotate: expanded ? "180deg" : 0 }}>
          <ChevronDownIcon className="h-6 w-6" />
        </motion.span>
      </button>

      <motion.ul
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: expanded ? "auto" : 0, opacity: expanded ? 1 : 0 }}
        className="flex flex-col gap-1 overflow-hidden"
      >
        {message.map((audit, idx) => (
          <li className="first:mt-2" key={idx}>
            <a
              data-turbo="false"
              href={`/audits/${audit.id}`}
              className="flex min-w-[200px] justify-between rounded px-2 py-2 odd:bg-gray-25 hover:bg-gray-50 hover:no-underline"
            >
              <div className="w-full">
                <div className="flex justify-between gap-2">
                  <p className="mb-1 font-base-medium">
                    <span className="capitalize">{audit.pastAction}</span>{" "}
                    <span>{audit.auditableType}</span>
                  </p>
                  <TimeAgo
                    datetime={audit.createdAt}
                    className="whitespace-nowrap"
                  />
                </div>
                <p>{audit.auditDescription}</p>
              </div>
            </a>
          </li>
        ))}
      </motion.ul>
    </MessageContainer>
  );
};
